"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaywrightTestReturnToken2 = exports.TestMockFull9Ssn = void 0;
var TestMockFull9Ssn;
(function (TestMockFull9Ssn) {
    // These still end in 5553 for any relevant whitelisting functionality, but
    // the first 5 are different for each user to ensure we do not overwrite credit_card_customer
    // records that are upserted based on person_guid (derived from ssn)
    // ===== PLAYWRIGHT TESTS =====
    TestMockFull9Ssn["PLAYWRIGHT_DEFAULT_USER_HAPPY_PATH"] = "112235553";
    TestMockFull9Ssn["PLAYWRIGHT_CHURN_RETENTION_USER_NO_BALANCE_SWEEP"] = "112245553";
    TestMockFull9Ssn["PLAYWRIGHT_CHURN_RETENTION_USER_ONLY_BALANCE_SWEEP"] = "112255553";
    TestMockFull9Ssn["PLAYWRIGHT_CHURN_RETENTION_USER_NO_CLI"] = "112265553";
    TestMockFull9Ssn["PLAYWRIGHT_CHURN_RETENTION_USER_NO_OFFER"] = "112275553";
    // ===== INTEGRATION TESTS =====
    TestMockFull9Ssn["INTEGRATION_TEST_DEFAULT_USER"] = "112285553";
    // This is a dummy value that is used for any tests that do not require a specific ssn
    // or does not require a credit_card_customer record to be upserted
    TestMockFull9Ssn["DUMMY_SSN_VALUE"] = "123456789";
})(TestMockFull9Ssn = exports.TestMockFull9Ssn || (exports.TestMockFull9Ssn = {}));
/**
 * Pre-seeded JODL return tokens used in Playwright tests. Uniqueness guaranteed
 * by unit test in aven_backend.
 */
var PlaywrightTestReturnToken2;
(function (PlaywrightTestReturnToken2) {
    PlaywrightTestReturnToken2["postPreQualStateModulesStatedIncomeFooter"] = "PLAYWRIGHTv4fvS53pe";
    PlaywrightTestReturnToken2["postPreQualStateModulesPersonalInfo"] = "PLAYWRIGHTv4dXm26Pg";
    PlaywrightTestReturnToken2["postPreQualStateModulesStatedIncomeApplicantAndCoApplicant"] = "PLAYWRIGHTv4G42sQiY";
    PlaywrightTestReturnToken2["notaryScheduling"] = "CYPRESSv4Tnl9Rav";
    PlaywrightTestReturnToken2["jodlDocument"] = "CYPRESSv4Tnl6Rae";
    PlaywrightTestReturnToken2["cli1"] = "CYPRESSe5cQQXhpO";
    PlaywrightTestReturnToken2["cli2"] = "CYPRESSLP1LPhlmr";
    PlaywrightTestReturnToken2["churnRetentionHappyPath"] = "CYPRESSv4dZ4aa99";
    PlaywrightTestReturnToken2["churnRetentionNoBalanceSweep"] = "CYPRESSv4dZ4aa80";
    PlaywrightTestReturnToken2["churnRetentionOnlyBalanceSweep"] = "CYPRESSv4dZ4aa81";
    PlaywrightTestReturnToken2["churnRetentionNoCli"] = "CYPRESSv4dZ4aa82";
    PlaywrightTestReturnToken2["churnRetentionNoOffer"] = "CYPRESSv4dZ4aa83";
    PlaywrightTestReturnToken2["notaryE2E"] = "CYPRESSv4Nub5Pcb";
    PlaywrightTestReturnToken2["instantBalanceTransferE2E"] = "CYPRESSv4DqeYq39";
    PlaywrightTestReturnToken2["multiProductHome"] = "PLAYWRIGHTv4Nub5Pcb";
    PlaywrightTestReturnToken2["multiProductUcc"] = "PLAYWRIGHTv4Msb6Obg";
})(PlaywrightTestReturnToken2 = exports.PlaywrightTestReturnToken2 || (exports.PlaywrightTestReturnToken2 = {}));
