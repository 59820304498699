<template>
    <div
        :class="(fullScreen ? '' : 'modal-no-full-screen') + ' modal fade tw-bg-[#00000080] ' + (show ? 'show' : '')"
        tabindex="-1"
        role="dialog"
        :style="{ display: show ? 'block' : 'none' }"
    >
        <div
            class="modal-dialog modal-dialog-centered"
            role="document"
        >
            <div
                class="modal-content"
                :style="{ paddingTop: `${getSafeAreaPaddingTop()}px`, paddingBottom: `${getSafeAreaPaddingBottom()}px` }"
            >
                <div
                    class="modal-header"
                    v-show="title"
                >
                    <img
                        v-show="!hideCloseButton"
                        class="close"
                        src="../assets/images/global/close.svg"
                        data-dismiss="modal"
                        @click="$emit('close')"
                        @keydown.enter="$emit('close')"
                        alt="Modal close button"
                    >
                    <h5 class="title">
                        {{ title }}
                    </h5>
                </div>
                <div class="modal-body">
                    <slot />
                </div>
            </div>
        </div>
    </div>
    <!-- NOTE: modal-backdrop is in App.vue to maintain scrollability on desktop -->
</template>

<script>
    import { logger } from '@/utils/logger'
    import { getSafeAreaPaddingBottom, getSafeAreaPaddingTop } from '@/utils/safeAreaUtils'

    export default {
        name: 'Modal',
        methods: { getSafeAreaPaddingTop, getSafeAreaPaddingBottom },
        props: {
            title: { type: String },
            show: { type: Boolean, default: false },
            hideCloseButton: { type: Boolean, default: false },
            fullScreen: { type: Boolean, default: false },
        },
        data() {
            return {
                isDeactivated: false,
            }
        },
        destroyed: function () {
            logger.info('Modal destroyed')
            // if a user times out, we want to remove these classes on destroyed
            document.body.classList.remove('modal-open')
            document.getElementById('modalBackdrop').classList.remove('show')
            document.getElementById('modalBackdrop').classList.remove('d-block')
        },
        activated() {
            logger.info('Modal activated')
            this.isDeactivated = false
            if (this.show) {
                document.body.classList.add('modal-open')
                document.getElementById('modalBackdrop').classList.add('show')
                document.getElementById('modalBackdrop').classList.add('d-block')
            }
        },
        deactivated: function () {
            // this is for keep-alive cases
            logger.info('Modal deactivated')
            this.isDeactivated = true
            if (this.show) {
                document.body.classList.remove('modal-open')
                document.getElementById('modalBackdrop').classList.remove('show')
                document.getElementById('modalBackdrop').classList.remove('d-block')
            }
        },
        watch: {
            show: function (value) {
                logger.info('Modal show changed to: ' + value)
                if (this.isDeactivated) {
                    return
                }
                if (value) {
                    document.body.classList.add('modal-open')
                    document.getElementById('modalBackdrop').classList.add('show')
                    document.getElementById('modalBackdrop').classList.add('d-block')
                } else {
                    document.body.classList.remove('modal-open')
                    document.getElementById('modalBackdrop').classList.remove('show')
                    document.getElementById('modalBackdrop').classList.remove('d-block')
                }
            },
        },
    }
</script>

<style lang="scss">
    @import 'node_modules/aven_shared/src/styles/components/modal';

    // Makes the modal not fill the entire screen on mobile
    .modal.modal-no-full-screen {
        .modal-dialog {
            .modal-content {
                margin: 16px;
                border-radius: $border-radius;
                height: auto;
            }
        }
    }
</style>
