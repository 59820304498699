"use strict";
// Vesta is the loan origination system (LOS) provider for mortgage, providing us functionality
// out of the box that specifically helps us meet strict mortgage requirements.
//
// Version 25.1
// Express dates as YYYY-MM-DD
// https://aven.vesta.com/api?version=_25_1
// https://aven.beta.vesta.com/api?version=_25_1
Object.defineProperty(exports, "__esModule", { value: true });
exports.VestaEconsentStatus = exports.VestaEconsentCaptureMethod = exports.VestaEmployerStatus = exports.VestaIncomeAmountType = exports.VestaIncomeType = exports.VestaAssetType = exports.VestaHmdaRaceDesignationTypePacificIslander = exports.VestaHmdaRaceDesignationType = exports.VestaHmdaRaceType = exports.VestaHmdaGenderType = exports.VestaHmdaEthnicityType = exports.VestaHmdaEthnicityOriginType = exports.VestaBankruptcyClosedType = exports.VestaBankruptcyChapterType = exports.VestaPriorPropertyTitleType = exports.VestaPriorPropertyUsageType = exports.VestaCitizenshipType = exports.VestaApplicationSubmitMethod = exports.VestaIncomeVerificationConsentType = exports.VestaCreditPullConsentType = exports.VestaMaritalStatus = exports.VestaPhoneNumberType = exports.VestaTaxIdentifierType = exports.VestaAddressStatus = exports.VestaAddressOccupancyType = exports.VestaPropertyValuationMethodType = exports.VestaPropertyUsageType = exports.VestaPropertyType = exports.VestaMortgageType = exports.VestaLienType = exports.VestaRefinanceCashOutDeterminationType = exports.VestaLoanType = exports.VestaLoanPurpose = exports.VestaEntityType = void 0;
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/OntologyEntityType
var VestaEntityType;
(function (VestaEntityType) {
    VestaEntityType["Loan"] = "Loan";
    VestaEntityType["Borrower"] = "Borrower";
    VestaEntityType["Property"] = "Property";
    VestaEntityType["Asset"] = "Asset";
    VestaEntityType["Income"] = "Income";
    VestaEntityType["Liability"] = "Liability";
    VestaEntityType["BorrowerBusiness"] = "BorrowerBusiness";
    VestaEntityType["ConcurrentFinancing"] = "ConcurrentFinancing";
    VestaEntityType["Proration"] = "Proration";
    VestaEntityType["ClosingCost"] = "ClosingCost";
    VestaEntityType["CreditInquiry"] = "CreditInquiry";
    VestaEntityType["PublicRecord"] = "PublicRecord";
    VestaEntityType["UnderwritingException"] = "UnderwritingException";
    VestaEntityType["PropertyValuation"] = "PropertyValuation";
    VestaEntityType["Document"] = "Document";
    VestaEntityType["PricingRequest"] = "PricingRequest";
    VestaEntityType["TaxTranscriptRequestForm"] = "TaxTranscriptRequestForm";
})(VestaEntityType = exports.VestaEntityType || (exports.VestaEntityType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/LoanPurpose
var VestaLoanPurpose;
(function (VestaLoanPurpose) {
    VestaLoanPurpose["Purchase"] = "Purchase";
    VestaLoanPurpose["Refinance"] = "Refinance";
})(VestaLoanPurpose = exports.VestaLoanPurpose || (exports.VestaLoanPurpose = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/LoanType
var VestaLoanType;
(function (VestaLoanType) {
    VestaLoanType["Mortgage"] = "Mortgage";
    VestaLoanType["HELOC"] = "HELOC";
    VestaLoanType["HomeEquityLoan"] = "HomeEquityLoan";
})(VestaLoanType = exports.VestaLoanType || (exports.VestaLoanType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/RefinanceCashOutDeterminationType
var VestaRefinanceCashOutDeterminationType;
(function (VestaRefinanceCashOutDeterminationType) {
    VestaRefinanceCashOutDeterminationType["CashOut"] = "CashOut";
    VestaRefinanceCashOutDeterminationType["NoOrLimitedCashOut"] = "NoOrLimitedCashOut";
})(VestaRefinanceCashOutDeterminationType = exports.VestaRefinanceCashOutDeterminationType || (exports.VestaRefinanceCashOutDeterminationType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/LienPriorityType
var VestaLienType;
(function (VestaLienType) {
    VestaLienType["FirstLien"] = "FirstLien";
    VestaLienType["SecondLien"] = "SecondLien";
    VestaLienType["ThirdLien"] = "ThirdLien";
    VestaLienType["FourthLien"] = "FourthLien";
})(VestaLienType = exports.VestaLienType || (exports.VestaLienType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/MortgageType
var VestaMortgageType;
(function (VestaMortgageType) {
    VestaMortgageType["Conventional"] = "Conventional";
    VestaMortgageType["FHA"] = "FHA";
    VestaMortgageType["USDA"] = "USDA";
    VestaMortgageType["VA"] = "VA";
})(VestaMortgageType = exports.VestaMortgageType || (exports.VestaMortgageType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/PropertyType
var VestaPropertyType;
(function (VestaPropertyType) {
    VestaPropertyType["SingleFamily"] = "SingleFamily";
    VestaPropertyType["Condominium"] = "Condominium";
    VestaPropertyType["Cooperative"] = "Cooperative";
    VestaPropertyType["TwoToFourUnitProperty"] = "TwoToFourUnitProperty";
    VestaPropertyType["ManufacturedHome"] = "ManufacturedHome";
    VestaPropertyType["PlannedUnitDevelopment"] = "PlannedUnitDevelopment";
    VestaPropertyType["Land"] = "Land";
    VestaPropertyType["MultiFamily"] = "MultiFamily";
})(VestaPropertyType = exports.VestaPropertyType || (exports.VestaPropertyType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/PropertyUsageType
var VestaPropertyUsageType;
(function (VestaPropertyUsageType) {
    VestaPropertyUsageType["PrimaryResidence"] = "PrimaryResidence";
    VestaPropertyUsageType["SecondHome"] = "SecondHome";
    VestaPropertyUsageType["Investment"] = "Investment";
    VestaPropertyUsageType["Other"] = "Other";
})(VestaPropertyUsageType = exports.VestaPropertyUsageType || (exports.VestaPropertyUsageType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/ValuationMethod
var VestaPropertyValuationMethodType;
(function (VestaPropertyValuationMethodType) {
    VestaPropertyValuationMethodType["FullAppraisal"] = "FullAppraisal";
    VestaPropertyValuationMethodType["PriorAppraisalUsed"] = "PriorAppraisalUsed";
    VestaPropertyValuationMethodType["DesktopAppraisal"] = "DesktopAppraisal";
    VestaPropertyValuationMethodType["AutomatedValuationModel"] = "AutomatedValuationModel";
    VestaPropertyValuationMethodType["BrokerPriceOpinion"] = "BrokerPriceOpinion";
    VestaPropertyValuationMethodType["DriveBy"] = "DriveBy";
    VestaPropertyValuationMethodType["TaxValuation"] = "TaxValuation";
    VestaPropertyValuationMethodType["Other"] = "Other";
    VestaPropertyValuationMethodType["None"] = "None";
    VestaPropertyValuationMethodType["DeskReview"] = "DeskReview";
    VestaPropertyValuationMethodType["FieldReview"] = "FieldReview";
    VestaPropertyValuationMethodType["HybridAppraisal"] = "HybridAppraisal";
})(VestaPropertyValuationMethodType = exports.VestaPropertyValuationMethodType || (exports.VestaPropertyValuationMethodType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/OccupancyType
var VestaAddressOccupancyType;
(function (VestaAddressOccupancyType) {
    VestaAddressOccupancyType["Own"] = "Own";
    VestaAddressOccupancyType["Rent"] = "Rent";
    VestaAddressOccupancyType["NoPrimaryHousingExpense"] = "NoPrimaryHousingExpense";
})(VestaAddressOccupancyType = exports.VestaAddressOccupancyType || (exports.VestaAddressOccupancyType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/AddressStatus
var VestaAddressStatus;
(function (VestaAddressStatus) {
    VestaAddressStatus["Current"] = "Current";
    VestaAddressStatus["Previous"] = "Previous";
})(VestaAddressStatus = exports.VestaAddressStatus || (exports.VestaAddressStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/TaxIdentifierType
var VestaTaxIdentifierType;
(function (VestaTaxIdentifierType) {
    VestaTaxIdentifierType["SocialSecurityNumber"] = "SocialSecurityNumber";
    VestaTaxIdentifierType["IndividualTaxpayerIdentificationNumber"] = "IndividualTaxpayerIdentificationNumber";
    // Vesta does not support EmployerIdentificationNumber
})(VestaTaxIdentifierType = exports.VestaTaxIdentifierType || (exports.VestaTaxIdentifierType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/PhoneNumberType
var VestaPhoneNumberType;
(function (VestaPhoneNumberType) {
    VestaPhoneNumberType["Home"] = "Home";
    VestaPhoneNumberType["Work"] = "Work";
    VestaPhoneNumberType["Mobile"] = "Mobile";
    VestaPhoneNumberType["Other"] = "Other";
})(VestaPhoneNumberType = exports.VestaPhoneNumberType || (exports.VestaPhoneNumberType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/MaritalStatus
var VestaMaritalStatus;
(function (VestaMaritalStatus) {
    VestaMaritalStatus["Married"] = "Married";
    VestaMaritalStatus["Separated"] = "Separated";
    VestaMaritalStatus["Unmarried"] = "Unmarried";
})(VestaMaritalStatus = exports.VestaMaritalStatus || (exports.VestaMaritalStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/CreditPullConsentType
var VestaCreditPullConsentType;
(function (VestaCreditPullConsentType) {
    VestaCreditPullConsentType["Verbal"] = "Verbal";
    VestaCreditPullConsentType["Written"] = "Written";
    VestaCreditPullConsentType["Electronic"] = "Electronic";
})(VestaCreditPullConsentType = exports.VestaCreditPullConsentType || (exports.VestaCreditPullConsentType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/IncomeVerificationConsentType
var VestaIncomeVerificationConsentType;
(function (VestaIncomeVerificationConsentType) {
    VestaIncomeVerificationConsentType["Verbal"] = "Verbal";
    VestaIncomeVerificationConsentType["Written"] = "Written";
    VestaIncomeVerificationConsentType["Electronic"] = "Electronic";
})(VestaIncomeVerificationConsentType = exports.VestaIncomeVerificationConsentType || (exports.VestaIncomeVerificationConsentType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/ApplicationSubmitMethod
var VestaApplicationSubmitMethod;
(function (VestaApplicationSubmitMethod) {
    VestaApplicationSubmitMethod["Email"] = "Email";
    VestaApplicationSubmitMethod["FaceToFace"] = "FaceToFace";
    VestaApplicationSubmitMethod["Fax"] = "Fax";
    VestaApplicationSubmitMethod["Internet"] = "Internet";
    VestaApplicationSubmitMethod["Mail"] = "Mail";
    VestaApplicationSubmitMethod["Telephone"] = "Telephone";
})(VestaApplicationSubmitMethod = exports.VestaApplicationSubmitMethod || (exports.VestaApplicationSubmitMethod = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/CitizenshipType
var VestaCitizenshipType;
(function (VestaCitizenshipType) {
    VestaCitizenshipType["USCitizen"] = "USCitizen";
    VestaCitizenshipType["PermanentResidentAlien"] = "PermanentResidentAlien";
    VestaCitizenshipType["NonPermanentResidentAlien"] = "NonPermanentResidentAlien";
    VestaCitizenshipType["NonResidentAlien"] = "NonResidentAlien";
})(VestaCitizenshipType = exports.VestaCitizenshipType || (exports.VestaCitizenshipType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/PriorPropertyUsageType
var VestaPriorPropertyUsageType;
(function (VestaPriorPropertyUsageType) {
    VestaPriorPropertyUsageType["Investment"] = "Investment";
    VestaPriorPropertyUsageType["PrimaryResidence"] = "PrimaryResidence";
    VestaPriorPropertyUsageType["SecondHome"] = "SecondHome";
    VestaPriorPropertyUsageType["FHASecondaryResidence"] = "FHASecondaryResidence";
})(VestaPriorPropertyUsageType = exports.VestaPriorPropertyUsageType || (exports.VestaPriorPropertyUsageType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/PriorPropertyTitleType
var VestaPriorPropertyTitleType;
(function (VestaPriorPropertyTitleType) {
    VestaPriorPropertyTitleType["JointWithOtherThanSpouse"] = "JointWithOtherThanSpouse";
    VestaPriorPropertyTitleType["JointWithSpouse"] = "JointWithSpouse";
    VestaPriorPropertyTitleType["Sole"] = "Sole";
})(VestaPriorPropertyTitleType = exports.VestaPriorPropertyTitleType || (exports.VestaPriorPropertyTitleType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/BankruptcyChapterType
var VestaBankruptcyChapterType;
(function (VestaBankruptcyChapterType) {
    VestaBankruptcyChapterType["ChapterSeven"] = "ChapterSeven";
    VestaBankruptcyChapterType["ChapterEleven"] = "ChapterEleven";
    VestaBankruptcyChapterType["ChapterTwelve"] = "ChapterTwelve";
    VestaBankruptcyChapterType["ChapterThirteen"] = "ChapterThirteen";
})(VestaBankruptcyChapterType = exports.VestaBankruptcyChapterType || (exports.VestaBankruptcyChapterType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/BankruptcyClosedType
var VestaBankruptcyClosedType;
(function (VestaBankruptcyClosedType) {
    VestaBankruptcyClosedType["Discharged"] = "Discharged";
    VestaBankruptcyClosedType["Dismissed"] = "Dismissed";
})(VestaBankruptcyClosedType = exports.VestaBankruptcyClosedType || (exports.VestaBankruptcyClosedType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/HmdaEthnicityOriginType
var VestaHmdaEthnicityOriginType;
(function (VestaHmdaEthnicityOriginType) {
    VestaHmdaEthnicityOriginType["Mexican"] = "Mexican";
    VestaHmdaEthnicityOriginType["PuertoRican"] = "PuertoRican";
    VestaHmdaEthnicityOriginType["Cuban"] = "Cuban";
    VestaHmdaEthnicityOriginType["Other"] = "Other";
})(VestaHmdaEthnicityOriginType = exports.VestaHmdaEthnicityOriginType || (exports.VestaHmdaEthnicityOriginType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/HmdaEthnicityType
var VestaHmdaEthnicityType;
(function (VestaHmdaEthnicityType) {
    VestaHmdaEthnicityType["HispanicOrLatino"] = "HispanicOrLatino";
    VestaHmdaEthnicityType["NotHispanicOrLatino"] = "NotHispanicOrLatino";
    VestaHmdaEthnicityType["PreferNotToDisclose"] = "PreferNotToDisclose";
})(VestaHmdaEthnicityType = exports.VestaHmdaEthnicityType || (exports.VestaHmdaEthnicityType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/HMDAGenderType
var VestaHmdaGenderType;
(function (VestaHmdaGenderType) {
    VestaHmdaGenderType["Male"] = "Male";
    VestaHmdaGenderType["Female"] = "Female";
    VestaHmdaGenderType["PreferNotToDisclose"] = "PreferNotToDisclose";
})(VestaHmdaGenderType = exports.VestaHmdaGenderType || (exports.VestaHmdaGenderType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/HMDARaceType
var VestaHmdaRaceType;
(function (VestaHmdaRaceType) {
    VestaHmdaRaceType["AmericanIndianOrAlaskaNative"] = "AmericanIndianOrAlaskaNative";
    VestaHmdaRaceType["Asian"] = "Asian";
    VestaHmdaRaceType["BlackOrAfricanAmerican"] = "BlackOrAfricanAmerican";
    VestaHmdaRaceType["NativeHawaiianOrOtherPacificIslander"] = "NativeHawaiianOrOtherPacificIslander";
    VestaHmdaRaceType["White"] = "White";
    VestaHmdaRaceType["PreferNotToDisclose"] = "PreferNotToDisclose";
})(VestaHmdaRaceType = exports.VestaHmdaRaceType || (exports.VestaHmdaRaceType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/HMDARaceDesignationType
var VestaHmdaRaceDesignationType;
(function (VestaHmdaRaceDesignationType) {
    VestaHmdaRaceDesignationType["AsianIndian"] = "AsianIndian";
    VestaHmdaRaceDesignationType["Chinese"] = "Chinese";
    VestaHmdaRaceDesignationType["Filipino"] = "Filipino";
    VestaHmdaRaceDesignationType["Japanese"] = "Japanese";
    VestaHmdaRaceDesignationType["Korean"] = "Korean";
    VestaHmdaRaceDesignationType["OtherAsian"] = "OtherAsian";
    VestaHmdaRaceDesignationType["Vietnamese"] = "Vietnamese";
})(VestaHmdaRaceDesignationType = exports.VestaHmdaRaceDesignationType || (exports.VestaHmdaRaceDesignationType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/HMDARaceDesignationTypePacificIslander
var VestaHmdaRaceDesignationTypePacificIslander;
(function (VestaHmdaRaceDesignationTypePacificIslander) {
    VestaHmdaRaceDesignationTypePacificIslander["GuamanianOrChamorro"] = "GuamanianOrChamorro";
    VestaHmdaRaceDesignationTypePacificIslander["NativeHawaiian"] = "NativeHawaiian";
    VestaHmdaRaceDesignationTypePacificIslander["OtherPacificIslander"] = "OtherPacificIslander";
    VestaHmdaRaceDesignationTypePacificIslander["Samoan"] = "Samoan";
})(VestaHmdaRaceDesignationTypePacificIslander = exports.VestaHmdaRaceDesignationTypePacificIslander || (exports.VestaHmdaRaceDesignationTypePacificIslander = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/AssetType
var VestaAssetType;
(function (VestaAssetType) {
    VestaAssetType["Bond"] = "Bond";
    VestaAssetType["BridgeLoanNotDeposited"] = "BridgeLoanNotDeposited";
    VestaAssetType["CertificateOfDepositTimeDeposit"] = "CertificateOfDepositTimeDeposit";
    VestaAssetType["CheckingAccount"] = "CheckingAccount";
    VestaAssetType["IndividualDevelopmentAccount"] = "IndividualDevelopmentAccount";
    VestaAssetType["LifeInsurance"] = "LifeInsurance";
    VestaAssetType["MoneyMarketFund"] = "MoneyMarketFund";
    VestaAssetType["MutualFund"] = "MutualFund";
    VestaAssetType["RetirementFund"] = "RetirementFund";
    VestaAssetType["SavingsAccount"] = "SavingsAccount";
    VestaAssetType["Stock"] = "Stock";
    VestaAssetType["StockOptions"] = "StockOptions";
    VestaAssetType["TrustAccount"] = "TrustAccount";
    VestaAssetType["CashOnHand"] = "CashOnHand";
    VestaAssetType["Other"] = "Other";
    VestaAssetType["PendingNetSaleProceedsFromRealEstateAssets"] = "PendingNetSaleProceedsFromRealEstateAssets";
    VestaAssetType["ProceedsFromSaleOfNonRealEstateAsset"] = "ProceedsFromSaleOfNonRealEstateAsset";
    VestaAssetType["ProceedsFromSecuredLoan"] = "ProceedsFromSecuredLoan";
    VestaAssetType["ProceedsFromUnsecuredLoan"] = "ProceedsFromUnsecuredLoan";
    VestaAssetType["GiftOfCash"] = "GiftOfCash";
    VestaAssetType["Grant"] = "Grant";
})(VestaAssetType = exports.VestaAssetType || (exports.VestaAssetType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/IncomeType
var VestaIncomeType;
(function (VestaIncomeType) {
    VestaIncomeType["AccessoryUnit"] = "AccessoryUnit";
    VestaIncomeType["Alimony"] = "Alimony";
    VestaIncomeType["AutomobileAllowance"] = "AutomobileAllowance";
    VestaIncomeType["Boarder"] = "Boarder";
    VestaIncomeType["CapitalGains"] = "CapitalGains";
    VestaIncomeType["ChildSupport"] = "ChildSupport";
    VestaIncomeType["DividendsInterest"] = "DividendsInterest";
    VestaIncomeType["Employment"] = "Employment";
    VestaIncomeType["FosterCare"] = "FosterCare";
    VestaIncomeType["HousingAllowance"] = "HousingAllowance";
    VestaIncomeType["HousingChoiceVoucherProgram"] = "HousingChoiceVoucherProgram";
    VestaIncomeType["MortgageCreditCertificate"] = "MortgageCreditCertificate";
    VestaIncomeType["MortgageDifferential"] = "MortgageDifferential";
    VestaIncomeType["NotesReceivableInstallment"] = "NotesReceivableInstallment";
    VestaIncomeType["Other"] = "Other";
    VestaIncomeType["Pension"] = "Pension";
    VestaIncomeType["PublicAssistance"] = "PublicAssistance";
    VestaIncomeType["Rental"] = "Rental";
    VestaIncomeType["Retirement"] = "Retirement";
    VestaIncomeType["Royalties"] = "Royalties";
    VestaIncomeType["SelfEmployment"] = "SelfEmployment";
    VestaIncomeType["SeparateMaintenance"] = "SeparateMaintenance";
    VestaIncomeType["SocialSecurity"] = "SocialSecurity";
    VestaIncomeType["TemporaryLeave"] = "TemporaryLeave";
    VestaIncomeType["Trust"] = "Trust";
    VestaIncomeType["UnemploymentBenefits"] = "UnemploymentBenefits";
    VestaIncomeType["VABenefitsNonEducational"] = "VABenefitsNonEducational";
    VestaIncomeType["Disability"] = "Disability";
})(VestaIncomeType = exports.VestaIncomeType || (exports.VestaIncomeType = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/IncomeAmountType
var VestaIncomeAmountType;
(function (VestaIncomeAmountType) {
    VestaIncomeAmountType["Weekly"] = "Weekly";
    VestaIncomeAmountType["BiWeekly"] = "BiWeekly";
    VestaIncomeAmountType["SemiMonthly"] = "SemiMonthly";
    VestaIncomeAmountType["Monthly"] = "Monthly";
    VestaIncomeAmountType["Quarterly"] = "Quarterly";
    VestaIncomeAmountType["SemiYearly"] = "SemiYearly";
    VestaIncomeAmountType["Annual"] = "Annual";
    VestaIncomeAmountType["AsOf"] = "AsOf";
    VestaIncomeAmountType["Hourly"] = "Hourly";
})(VestaIncomeAmountType = exports.VestaIncomeAmountType || (exports.VestaIncomeAmountType = {}));
var VestaEmployerStatus;
(function (VestaEmployerStatus) {
    VestaEmployerStatus["Current"] = "Current";
    VestaEmployerStatus["Previous"] = "Previous";
})(VestaEmployerStatus = exports.VestaEmployerStatus || (exports.VestaEmployerStatus = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/EconsentCaptureMethod
var VestaEconsentCaptureMethod;
(function (VestaEconsentCaptureMethod) {
    VestaEconsentCaptureMethod["Electronic"] = "Electronic";
    VestaEconsentCaptureMethod["Verbal"] = "Verbal";
})(VestaEconsentCaptureMethod = exports.VestaEconsentCaptureMethod || (exports.VestaEconsentCaptureMethod = {}));
// https://aven.beta.vesta.com/api?version=_25_1#/schemas/EconsentStatus
var VestaEconsentStatus;
(function (VestaEconsentStatus) {
    VestaEconsentStatus["Accept"] = "Accept";
    VestaEconsentStatus["Decline"] = "Decline";
    VestaEconsentStatus["Withdrawn"] = "Withdrawn";
})(VestaEconsentStatus = exports.VestaEconsentStatus || (exports.VestaEconsentStatus = {}));
