"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotaryFailureReason = exports.referralFromText = exports.ReferralFrom = exports.NotarySessionRole = void 0;
var NotarySessionRole;
(function (NotarySessionRole) {
    NotarySessionRole["notary"] = "Notary";
    NotarySessionRole["applicant"] = "Applicant";
    NotarySessionRole["witness"] = "Witness";
    NotarySessionRole["attorney"] = "Attorney";
    NotarySessionRole["plotter"] = "Plotter";
    NotarySessionRole["visitor"] = "Visitor";
    NotarySessionRole["mlo"] = "MLO";
})(NotarySessionRole = exports.NotarySessionRole || (exports.NotarySessionRole = {}));
var ReferralFrom;
(function (ReferralFrom) {
    ReferralFrom["directMail"] = "directMail";
    ReferralFrom["facebookOrInstagram"] = "facebookOrInstagram";
    ReferralFrom["google"] = "google";
    ReferralFrom["tvAd"] = "tvAd";
    ReferralFrom["youtube"] = "youtube";
    ReferralFrom["radio"] = "radio";
    ReferralFrom["podcast"] = "podcast";
    ReferralFrom["creditKarma"] = "creditKarma";
    ReferralFrom["friend"] = "friend";
    ReferralFrom["dontRemember"] = "dontRemember";
    ReferralFrom["other"] = "other";
})(ReferralFrom = exports.ReferralFrom || (exports.ReferralFrom = {}));
exports.referralFromText = {
    [ReferralFrom.directMail]: 'Mail',
    [ReferralFrom.facebookOrInstagram]: 'Facebook/Instagram',
    [ReferralFrom.google]: 'Google',
    [ReferralFrom.tvAd]: 'TV Ad',
    [ReferralFrom.youtube]: 'YouTube',
    [ReferralFrom.radio]: 'Radio',
    [ReferralFrom.podcast]: 'Podcast',
    [ReferralFrom.creditKarma]: 'Credit Karma',
    [ReferralFrom.friend]: 'From a friend',
    [ReferralFrom.dontRemember]: "Don't remember",
    [ReferralFrom.other]: 'Other',
};
var NotaryFailureReason;
(function (NotaryFailureReason) {
    NotaryFailureReason["APPLICANT_STARTED_PROD_SESSION_NEVER_ENTERED"] = "APPLICANT_STARTED_PROD_SESSION_NEVER_ENTERED";
    NotaryFailureReason["APPLICANT_DIDNT_REALIZE_IT_WAS_A_HELOC"] = "APPLICANT_DIDNT_REALIZE_IT_WAS_A_HELOC";
    NotaryFailureReason["APPLICANT_VERBALLY_DECLINES_THE_PRODUCT"] = "APPLICANT_VERBALLY_DECLINES_THE_PRODUCT";
    NotaryFailureReason["APPLICANT_DIDNT_REALIZE_THEY_WERE_MEETING_A_NOTARY_NOW"] = "APPLICANT_DIDNT_REALIZE_THEY_WERE_MEETING_A_NOTARY_NOW";
    NotaryFailureReason["APPLICANT_DRIVING"] = "APPLICANT_DRIVING";
    NotaryFailureReason["APPLICANT_NEEDS_A_TRANSLATOR"] = "APPLICANT_NEEDS_A_TRANSLATOR";
    NotaryFailureReason["APPLICANT_WANTS_TO_REVIEW_THE_AGREEMENT_ON_THEIR_OWN_TIME"] = "APPLICANT_WANTS_TO_REVIEW_THE_AGREEMENT_ON_THEIR_OWN_TIME";
    NotaryFailureReason["NOTARY_MISSING_VALID_ID_IN_PERSON"] = "NOTARY_MISSING_VALID_ID_IN_PERSON";
    NotaryFailureReason["NOTARY_SUSPECTED_FRAUD"] = "NOTARY_SUSPECTED_FRAUD";
    NotaryFailureReason["NOTARY_ABUSIVE_BEHAVIOR_TOWARDS_NOTARY"] = "NOTARY_ABUSIVE_BEHAVIOR_TOWARDS_NOTARY";
    NotaryFailureReason["NOTARY_THEY_ARE_NOT_SIGNING_KNOWINGLY_AND_VOLUNTARILY"] = "NOTARY_THEY_ARE_NOT_SIGNING_KNOWINGLY_AND_VOLUNTARILY";
    NotaryFailureReason["RAN_OUT_OF_TIME_THEY_ENTERED_THE_SESSION_LATE"] = "RAN_OUT_OF_TIME_THEY_ENTERED_THE_SESSION_LATE";
    NotaryFailureReason["RAN_OUT_OF_TIME_THEY_ARE_READING_THE_AGREEMENT"] = "RAN_OUT_OF_TIME_THEY_ARE_READING_THE_AGREEMENT";
    NotaryFailureReason["RAN_OUT_OF_TIME_THEY_DIDNT_ENTER_THE_SESSION_START_NEXT_CUSTOMER_UNAVAILABLE"] = "RAN_OUT_OF_TIME_THEY_DIDNT_ENTER_THE_SESSION_START_NEXT_CUSTOMER_UNAVAILABLE";
    NotaryFailureReason["QUESTIONS_I_DONT_KNOW_THE_ANSWER_TO"] = "QUESTIONS_I_DONT_KNOW_THE_ANSWER_TO";
    NotaryFailureReason["QUESTIONS_I_AM_UNCOMFORTABLE_ANSWERING"] = "QUESTIONS_I_AM_UNCOMFORTABLE_ANSWERING";
    NotaryFailureReason["QUESTIONS_I_AM_NOT_ALLOWED_TO_ANSWER"] = "QUESTIONS_I_AM_NOT_ALLOWED_TO_ANSWER";
    NotaryFailureReason["SPOUSE_MARRIED_OR_SEPARATED_DOESNT_WANT_THE_SPOUSE_TO_SIGN"] = "SPOUSE_MARRIED_OR_SEPARATED_DOESNT_WANT_THE_SPOUSE_TO_SIGN";
    NotaryFailureReason["SPOUSE_SPOUSE_IS_DECEASED"] = "SPOUSE_SPOUSE_IS_DECEASED";
    NotaryFailureReason["SPOUSE_THEY_ARE_DIVORCED"] = "SPOUSE_THEY_ARE_DIVORCED";
    NotaryFailureReason["DOCS_TRUST_INFORMATION_IS_INCORRECT"] = "DOCS_TRUST_INFORMATION_IS_INCORRECT";
    NotaryFailureReason["DOCS_NAME_IS_INCORRECT"] = "DOCS_NAME_IS_INCORRECT";
    NotaryFailureReason["DOCS_MISSING_A_PERSON"] = "DOCS_MISSING_A_PERSON";
    NotaryFailureReason["DOCS_THERE_IS_AN_EXTRA_PERSON"] = "DOCS_THERE_IS_AN_EXTRA_PERSON";
    NotaryFailureReason["TECH_DOCUSIGN_IS_NOT_LOADING"] = "TECH_DOCUSIGN_IS_NOT_LOADING";
    NotaryFailureReason["TECH_DOCUSIGN_REGENERATION_BUTTON_NOT_SHOWING_OR_WORKING"] = "TECH_DOCUSIGN_REGENERATION_BUTTON_NOT_SHOWING_OR_WORKING";
    NotaryFailureReason["TECH_START_NEXT_CUSTOMER_BUTTON_IS_NOT_WORKING"] = "TECH_START_NEXT_CUSTOMER_BUTTON_IS_NOT_WORKING";
    NotaryFailureReason["TECH_CANNOT_HEAR_OR_SEE_AT_ALL"] = "TECH_CANNOT_HEAR_OR_SEE_AT_ALL";
    NotaryFailureReason["TECH_LAGS_IN_HEARING_AND_SEEING"] = "TECH_LAGS_IN_HEARING_AND_SEEING";
    NotaryFailureReason["TECH_ECHOING_IN_AUDIO"] = "TECH_ECHOING_IN_AUDIO";
    NotaryFailureReason["COOWNER_DONT_WANT_SPOUSE_TO_SIGN"] = "COOWNER_DONT_WANT_SPOUSE_TO_SIGN";
    NotaryFailureReason["COOWNER_DONT_HAVE_SECONDARY_PHONE_NUMBERS"] = "COOWNER_DONT_HAVE_SECONDARY_PHONE_NUMBERS";
    NotaryFailureReason["COOWNER_FORM_INCORRECT"] = "COOWNER_FORM_INCORRECT";
    NotaryFailureReason["OTHER"] = "OTHER";
    NotaryFailureReason["DEPRECATED_FAILED_KBA"] = "FAILED_KBA";
    NotaryFailureReason["DEPRECATED_APPLICANT_NO_SHOW"] = "APPLICANT_NO_SHOW";
    NotaryFailureReason["DEPRECATED_APPLICANT_TECHNICAL_ISSUES"] = "APPLICANT_TECHNICAL_ISSUES";
    NotaryFailureReason["DEPRECATED_AVEN_TECHNICAL_ISSUES"] = "AVEN_TECHNICAL_ISSUES";
    NotaryFailureReason["DEPRECATED_APPLICANT_MISSING_DOCUMENTATION"] = "APPLICANT_MISSING_DOCUMENTATION";
    NotaryFailureReason["DEPRECATED_APPLICANT_REVIEW"] = "APPLICANT_REVIEW";
    NotaryFailureReason["DEPRECATED_APPLICANT_DECLINES"] = "APPLICANT_DECLINES";
    NotaryFailureReason["DEPRECATED_DOC_DATA_ISSUE_MARITAL_STATUS"] = "DOC_DATA_ISSUE_MARITAL_STATUS";
    NotaryFailureReason["DEPRECATED_DOC_DATA_ISSUE_TRUSTS"] = "DOC_DATA_ISSUE_TRUSTS";
    NotaryFailureReason["DEPRECATED_DOC_DATA_ISSUE_NAMES_OR_SIGNER_COUNT"] = "DOC_DATA_ISSUE_NAMES_OR_SIGNER_COUNT";
    NotaryFailureReason["DEPRECATED_DOC_DATA_ISSUE_OTHER"] = "DOC_DATA_ISSUE_OTHER";
    NotaryFailureReason["DEPRECATED_FRAUD_SUSPECTED"] = "FRAUD_SUSPECTED";
    NotaryFailureReason["DEPRECATED_OUT_OF_TIME"] = "OUT_OF_TIME";
})(NotaryFailureReason = exports.NotaryFailureReason || (exports.NotaryFailureReason = {}));
