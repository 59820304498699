"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreQualAction = void 0;
var PreQualAction;
(function (PreQualAction) {
    // complete, we will send this action to the front end to route applicant to the right page
    PreQualAction["experianFail"] = "experianFail";
    PreQualAction["experianFrozen"] = "experianFrozen";
    PreQualAction["coApplicantExperianFrozen"] = "coApplicantExperianFrozen";
    PreQualAction["experianNoFile"] = "experianNoFile";
    PreQualAction["coApplicantExperianNoFile"] = "coApplicantExperianNoFile";
    PreQualAction["experianNotFound"] = "experianNotFound";
    PreQualAction["coApplicantExperianNotFound"] = "coApplicantExperianNotFound";
    PreQualAction["isIneligible"] = "isIneligible";
    PreQualAction["skipPrequal"] = "skipPrequal";
    PreQualAction["showPrequal"] = "showPrequal";
    PreQualAction["autoAcceptAndSkipPrequal"] = "autoAcceptAndSkipPrequal";
    PreQualAction["offerAddCoApplicant"] = "offerAddCoApplicant";
    PreQualAction["blockRepeatApplicant"] = "blockRepeatApplicant";
    PreQualAction["remedyOwnerNameMismatch"] = "remedyOwnerNameMismatch";
    PreQualAction["remedyOwnerNameMismatchTokenMatch"] = "remedyOwnerNameMismatchTokenMatch";
    PreQualAction["blockedApplication"] = "blockedApplication";
    PreQualAction["underwritingFail"] = "underwritingFail";
    PreQualAction["unsupportedCounty"] = "unsupportedCounty";
    PreQualAction["unsupportedFirstLienInState"] = "unsupportedLienPositionInState";
    PreQualAction["homeOwnership"] = "homeOwnership";
    PreQualAction["homeIsLLC"] = "homeIsLLC";
    PreQualAction["homeIsTrustAndRin"] = "homeIsTrustAndRin";
    PreQualAction["homeIsTrustAndNOO"] = "homeIsTrustAndNOO";
    PreQualAction["homeIsTrustInNotSupportedState"] = "homeIsTrustInNotSupportedState";
    PreQualAction["denied"] = "denied";
    PreQualAction["humanInvestigate"] = "humanInvestigate";
    PreQualAction["expired"] = "expired";
    PreQualAction["disputableProviderData"] = "disputableProviderData";
    PreQualAction["withdrawn"] = "withdrawn";
    PreQualAction["lienInformationVerification"] = "lienInformationVerification";
    PreQualAction["missingLienAttestation"] = "missingLienAttestation";
    PreQualAction["missingUserProvidedAllLienInfo"] = "missingUserProvidedAllLienInfo";
    PreQualAction["missingAssetVerification"] = "missingAssetVerification";
})(PreQualAction = exports.PreQualAction || (exports.PreQualAction = {}));
