"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpgradeCardType = void 0;
// This enum represents the type of upgrade card we will show to UCC cardholdlers in aven my and advisor users with specific xpn attributes
var UpgradeCardType;
(function (UpgradeCardType) {
    UpgradeCardType["helocRefi"] = "helocRefi";
    UpgradeCardType["personalLoanRefi"] = "personalLoanRefi";
    UpgradeCardType["genericAvenCash"] = "genericAvenCash";
    UpgradeCardType["creditCardBalanceTransfer"] = "creditCardBalanceTransfer";
    UpgradeCardType["none"] = "none";
})(UpgradeCardType = exports.UpgradeCardType || (exports.UpgradeCardType = {}));
