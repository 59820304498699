"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUIRED_STATE_MODULES_BEFORE_PRE_QUAL_ACCEPTED = exports.StateModulesSlotName = void 0;
// Valid slot names for state modules
var StateModulesSlotName;
(function (StateModulesSlotName) {
    StateModulesSlotName["PersonalInfo"] = "PersonalInfo";
    StateModulesSlotName["PreQualFooter"] = "PreQualFooter";
    StateModulesSlotName["WelcomeScreen"] = "WelcomeScreen";
    StateModulesSlotName["StatedIncome"] = "StatedIncome";
    StateModulesSlotName["StatedIncomeApplicant"] = "StatedIncomeApplicant";
    StateModulesSlotName["StatedIncomeCoApplicant"] = "StatedIncomeCoApplicant";
    StateModulesSlotName["StatedIncomeFooter"] = "StatedIncomeFooter";
    StateModulesSlotName["OfferFooter"] = "OfferFooter";
    StateModulesSlotName["ApplicantConfirmPii"] = "ApplicantConfirmPii";
})(StateModulesSlotName = exports.StateModulesSlotName || (exports.StateModulesSlotName = {}));
/**
 * !!!IMPORTANT!!!
 * If you add a new slot name w/ value true, you must add a StateFormModule component for it on PostPreQualStateModules.vue
 */
// Todo Is there a better way to enforce that PostPreQualStateModules.vue has all needed slots?
const SLOT_NAME_REQUIRED_BEFORE_PRE_QUAL_ACCEPTED = {
    [StateModulesSlotName.PersonalInfo]: true,
    [StateModulesSlotName.StatedIncome]: true,
    [StateModulesSlotName.StatedIncomeFooter]: true,
    [StateModulesSlotName.StatedIncomeApplicant]: true,
    [StateModulesSlotName.StatedIncomeCoApplicant]: true,
    [StateModulesSlotName.PreQualFooter]: false,
    [StateModulesSlotName.WelcomeScreen]: false,
    [StateModulesSlotName.OfferFooter]: false,
    [StateModulesSlotName.ApplicantConfirmPii]: false,
};
exports.REQUIRED_STATE_MODULES_BEFORE_PRE_QUAL_ACCEPTED = Object.keys(SLOT_NAME_REQUIRED_BEFORE_PRE_QUAL_ACCEPTED).filter((slotName) => {
    return SLOT_NAME_REQUIRED_BEFORE_PRE_QUAL_ACCEPTED[slotName];
});
