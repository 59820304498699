"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenAdvisorUpgradeStatus = exports.AvenAdvisorUpgradeJobStatus = exports.AvenAdvisorUpgradePath = void 0;
var AvenAdvisorUpgradePath;
(function (AvenAdvisorUpgradePath) {
    AvenAdvisorUpgradePath["UCC"] = "UCC";
    /**
     * Gets potential Aven upgrades based on the user's credit report.
     */
    AvenAdvisorUpgradePath["CREDIT_REPORT_BASED_UPGRADES"] = "CREDIT_REPORT_BASED_UPGRADES";
})(AvenAdvisorUpgradePath = exports.AvenAdvisorUpgradePath || (exports.AvenAdvisorUpgradePath = {}));
var AvenAdvisorUpgradeJobStatus;
(function (AvenAdvisorUpgradeJobStatus) {
    AvenAdvisorUpgradeJobStatus["Success"] = "Success";
    AvenAdvisorUpgradeJobStatus["Pending"] = "Pending";
    AvenAdvisorUpgradeJobStatus["Failure"] = "Failure";
})(AvenAdvisorUpgradeJobStatus = exports.AvenAdvisorUpgradeJobStatus || (exports.AvenAdvisorUpgradeJobStatus = {}));
var AvenAdvisorUpgradeStatus;
(function (AvenAdvisorUpgradeStatus) {
    AvenAdvisorUpgradeStatus["noAction"] = "noAction";
    AvenAdvisorUpgradeStatus["startDryrunPrequal"] = "startDryrunPrequal";
    AvenAdvisorUpgradeStatus["dryrunPrequalified"] = "dryrunPrequalified";
    AvenAdvisorUpgradeStatus["dryrunDenied"] = "dryrunDenied";
    AvenAdvisorUpgradeStatus["realLoanApplicationCreated"] = "realLoanApplicationCreated";
    AvenAdvisorUpgradeStatus["creditReportBasedUpgradesDetermined"] = "creditReportBasedUpgradesDetermined";
})(AvenAdvisorUpgradeStatus = exports.AvenAdvisorUpgradeStatus || (exports.AvenAdvisorUpgradeStatus = {}));
