import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import isEmpty from 'lodash/isEmpty'
import utilInspect from 'browser-util-inspect'
import axios, { AxiosInstance } from 'axios'

export const LogLevel = {
    // mirrors the levels used on the backend
    debug: 'DEBUG',
    log: 'INFO',
    info: 'INFO',
    warn: 'WARN',
    error: 'ERROR',
    fatal: 'FATAL',
}

/**
 * We use this as the error that we send to Sentry when our code only sends an error message,
 * but not a thrown Error (or Error subclass). For example:
 * logger.error('some message without an error')
 * As opposed to:
 * logger.error('some message with an error', error)
 *
 * Without this message wrapper, we can't actually group different Sentry messages because Sentry
 * automatically tries to group by stacktrace, which is not appropriate for error messages without
 * thrown errors.
 */
class SentryMessage extends Error {
    constructor(msg: string) {
        super(msg)
        this.name = 'SentryMessage'
        Object.setPrototypeOf(this, new.target.prototype)
    }
}

export const inspect = (msg: unknown) => {
    return utilInspect(msg)?.replace(/[\s]*[\n][\s]*/g, '; ')
}

// these are error messages we want to demote to 'warn' severity instead of 'exception' severity
const demotedErrorMessages = [
    /Network Error/gi, // A temporary network problem that is /usually/ the on client's side / mobile networks / etc
    /Unexpected token/gi, // This is caused when we deploy while someone is on the site and they get a 404 from the missing file
    /SyntaxError/gi, // This pops up on offbrand browsers, esp ones that inject their own JS
    /Loading.*chunk.*failed./gi, // This is also caused by a bad client-side cache and/or deploy
    /Request aborted/gi, // This is caused when an in-flight HTTP request is cancelled (while reloading, changing pages, etc)
    /Script error\./gi, // This is caused when a script loaded from a third-party fails for any reason. Browser does not provide any details even about the third-party origin (see: https://sentry.io/answers/script-error/)
]

const ignoreErrors = [
    /(?=.*?(Max retry limit of 2 exceeded with error))(?=.*?(still_here))/gi, // This is a noisy sentry for when we retry sending the 'still_here' event which pings our backend frequently
]

interface LoggerOptions {
    avenProject: string
    sentryDSN: string
    logProcessorBaseUrl?: string
    clientSideLogsEnabled: boolean
    getMetadata: () => Record<string, string>
}

export class Logger {
    private readonly clientSideLogsEnabled: boolean
    private readonly avenProject: string
    private readonly loggerHttpClient?: AxiosInstance
    private isNetworkLoggingEnabled = true
    private readonly getMetadata: () => Record<string, string>

    constructor({ avenProject, sentryDSN, logProcessorBaseUrl, getMetadata, clientSideLogsEnabled }: LoggerOptions) {
        this.avenProject = avenProject
        this.getMetadata = getMetadata
        this.clientSideLogsEnabled = clientSideLogsEnabled

        if (logProcessorBaseUrl) {
            this.loggerHttpClient = axios.create({
                baseURL: logProcessorBaseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
                responseType: 'text',
                timeout: 10_000,
            })
        }
        const isScraper = /google|fbid|facebook|fbav|fb_/gi.test(navigator.userAgent)
        const isProdEnv = process.env.VUE_APP_NODE_ENV === 'production'
        // Strip http/https from URLs
        if (isProdEnv && !isScraper) {
            console.info('[INFO] Allowing logs to go to sentry')
            Sentry.init({
                dsn: sentryDSN,
                environment: process.env.VUE_APP_NODE_ENV,
                release: process.env.VUE_APP_SENTRY_RELEASE,
                maxValueLength: 1024 * 8,
                attachStacktrace: true,
                defaultIntegrations: false,
                ignoreErrors,
                integrations: [Sentry.functionToStringIntegration(), Sentry.inboundFiltersIntegration(), Sentry.breadcrumbsIntegration(), Sentry.linkedErrorsIntegration()],
                initialScope: {
                    tags: {
                        // add any global static tags here
                        avenProject: this.avenProject,
                    },
                },
            })
        } else {
            console.info('[INFO] Refusing to log development and/or Googlebot to sentry')
        }

        Vue.config.errorHandler = (err) => {
            // Can't serialize 'vm', which is a recursively defined Vue object (also no point)
            // 'info' isn't that useful, only has a short string description
            // err is the actual stack trace
            const msg = `vue error: ${err}\t${err.stack}`
            if (demotedErrorMessages.some((regex) => regex.test(String(err.message)))) {
                this.warn(msg, err)
            } else {
                this.error(msg, err)
            }
        }

        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            // See: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
            if (/Loading.*chunk.*failed./i.test(event?.reason?.message || '')) {
                this.info('Reloading page to fix stale chunk error')
                this.logMessageToSentry(`window.onunhandledrejection error: Reloading page to fix stale chunk error: ${inspect(event.reason)}`, 'warning', null, {
                    isPossibleNetworkIssue: true,
                })
                return window.location.reload()
            }

            const reason = event.reason
            const msg = `window.onunhandledrejection error: ${inspect(reason)}`
            if (demotedErrorMessages.some((regex) => regex.test(String(reason)))) {
                this.warn(msg, reason instanceof Error ? reason : undefined, event)
            } else {
                this.error(msg, reason instanceof Error ? reason : undefined, event)
            }
        }

        window.onerror = (message, source, lineno, colno, error) => {
            const msg = `window.onerror error: ${inspect({ message, source, lineno, colno, error })}`
            if (demotedErrorMessages.some((regex) => regex.test(String(message)))) {
                this.warn(msg, error, message)
            } else {
                this.error(msg, error, message)
            }
        }
    }

    public debug = (message: string) => {
        if (this.clientSideLogsEnabled) {
            console.debug(message)
        }
        this.trySendToBackend({ message, level: LogLevel.debug })
    }

    public log = (message: string) => {
        if (this.clientSideLogsEnabled) {
            console.log(message)
        }
        this.trySendToBackend({ message, level: LogLevel.log })
    }

    public info = (message: string) => {
        if (this.clientSideLogsEnabled) {
            console.info(message)
        }
        this.trySendToBackend({ message, level: LogLevel.info })
    }

    public warn = (message: string, error?: any, event?: Event | PromiseRejectionEvent | string) => {
        if (this.clientSideLogsEnabled) {
            console.warn(message, error, event)
        }
        this.trySendToBackend({ message, event, level: LogLevel.warn, error })
    }

    public error = (message: string, error?: any, event?: Event | PromiseRejectionEvent | string) => {
        try {
            message = this.formatMessage(message)
            if (!message) {
                return // Don't send empty messages
            }

            // Redirect network errors to warn() so we don't trigger a high-severity Sentry and
            // page the on-call engineer
            if (message?.includes('Network Error')) {
                this.warn(message, error, event)
                return
            }

            if (this.clientSideLogsEnabled) {
                console.error(message, event, error)
            }
        } catch (e) {
            message = `NOTE: Failed to pre-process logger.error message due to error ${e}! ` + message
        }

        this.trySendToBackend({ message, event, level: LogLevel.error, error })
    }

    public fatal = (message: string, error?: any, event?: Event | PromiseRejectionEvent | string) => {
        try {
            message = this.formatMessage(message)
            if (!message) {
                return // Don't send empty messages
            }

            // Redirect network errors to error() so we don't trigger a high-severity Sentry and
            // page the on-call engineer
            if (message?.includes('Network Error')) {
                this.error(message, error, event)
                return
            }

            if (this.clientSideLogsEnabled) {
                console.error(message, event, error)
            }
        } catch (e) {
            message = `NOTE: Failed to pre-process logger.fatal message due to error ${e}! ` + message
        }

        this.trySendToBackend({ message, event, level: LogLevel.fatal, error })
    }

    private logMessageToProcessor = async (message: string, levelLabel = 'INFO', error?: any): Promise<void> => {
        if (!this.loggerHttpClient) {
            // This does not work in dev but console logs will still be available in the browser
            // so not a huge reason to dupe them here
            return
        }
        const metadata = { source: this.avenProject, ...this.getMetadata() }
        await this.loggerHttpClient.post('/', { levelLabel, message, metadata, error: error ? inspect(error) : undefined })
    }

    private trySendToBackend = async ({ message, level, error, event }: { message: string; event?: Event | PromiseRejectionEvent | string; level?: string; error?: any }) => {
        message = this.formatMessage(message)
        if (!message || !this.isNetworkLoggingEnabled) {
            return // Don't send empty messages
        }

        try {
            if (typeof event === 'string') {
                message += `\tEvent: ${event}`
            } else if (typeof event !== 'undefined') {
                message += `\tEventJSON: ${JSON.stringify(event)}`
            }
        } catch (e) {
            message = `NOTE: Failed to append some items to message due to error ${e}! ` + message
        }

        try {
            await this.logMessageToProcessor(message, level, error)

            if (level === LogLevel.error || level === LogLevel.fatal) {
                const severity: Sentry.SeverityLevel = level === LogLevel.error ? 'error' : 'fatal'
                this.logMessageToSentry(message, severity, error)
            } else {
                let severity: Sentry.SeverityLevel
                if (level === LogLevel.info) {
                    severity = 'info'
                } else if (level === LogLevel.log) {
                    severity = 'info'
                } else {
                    severity = 'debug'
                }
                this.addLogBreadcrumbToSentry(severity, message)
            }
        } catch (error) {
            if (this.clientSideLogsEnabled) {
                console.error(`Could not log message to backend due to error: ${inspect(error as object)}\nThe message was ${message}`)
            }
            this.logMessageToSentry(`Could not log message to backend: ${message}`, 'error', error, { isPossibleNetworkIssue: true })
        }
    }

    /**
     * Breadcrumbs are used to create a trail of events prior to an issue.
     * https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/
     */
    private addLogBreadcrumbToSentry = (severity: Sentry.SeverityLevel, message: string) => {
        Sentry.addBreadcrumb({
            // https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
            type: 'info',
            level: severity,
            // We need to truncate breadcrumb messages because Sentry will throw a 413 Payload too large
            // error in certain instances since we create very large log messages. NOTE THAT THIS IS NOT
            // TANTAMOUNT TO TRUNCATING LOGS! The logs are still fully available in CloudWatch.
            message: message?.slice(0, 256),
        })
    }

    private logMessageToSentry(message: string, severity: Sentry.SeverityLevel, error?: any, tags?: object) {
        try {
            const captureContext: any = {
                level: severity ?? 'fatal',
                extra: {
                    message,
                    // @ts-ignore navigator connection hasn't been standardized yet.
                    networkQuality: `type: ${navigator?.connection?.effectiveType}, downlink: ${navigator?.connection?.downlink}, online: ${navigator?.onLine}`,
                },
                tags: {
                    ...this.getMetadata(),
                    ...tags,
                },
            }
            const errorToSend = error ?? new SentryMessage(message)
            Sentry.captureException(errorToSend, captureContext)
        } catch (e) {
            Sentry.captureException(new Error(`Failed to capture exception due to error: ${e}\nException was ${message}`))
        }
    }

    private formatMessage = (message: string): string => {
        if (!message) {
            return ''
        }
        if (typeof message !== 'string') {
            if (isEmpty(message)) {
                return ''
            }
            return inspect(message)
        }

        return message.trim()
    }

    public setNetworkLogging = (enabled: boolean) => {
        this.isNetworkLoggingEnabled = enabled
    }
}
